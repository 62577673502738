import { Directive, Input, Optional, Host, SkipSelf, ElementRef, Renderer2 } from '@angular/core';
import { ControlContainer, AbstractControl, FormGroupName, FormArrayName } from '@angular/forms';

@Directive({
  selector: '[npFcvName]',
  host: {
    '[class.np-fcv-valid]': 'isValid',
    '[class.np-fcv-invalid]': 'isInvalid',
  },
  standalone: true
})
export class NpFcvNameDirective {

  get isValid(): boolean {

    if (this._names === undefined) {
      return true;
    }


    let result = this._names.every(n => {
      return this.chkValid(n);
    })

    if (this.npValidClass) {
      if (result) {
        this.addCustomClass(this.npValidClass);
      } else {
        this.removeCustomClass(this.npValidClass)
      }
      return false;
    } else {
      return result;
    }
  }

  get isInvalid(): boolean {

    if (this._names === undefined) {
      return false;
    }

    let result = this._names.some(n => {
      return this.chkInvalid(n);
    })

    if (this.npInvalidClass) {
      if (result) {
        this.addCustomClass(this.npInvalidClass);
      } else {
        this.removeCustomClass(this.npInvalidClass)
      }
      return false;
    } else {
      return result;
    }
  }

  addCustomClass(klass: string) {
    if (this._ngEl.nativeElement.classList.contains(klass) === false) {
      this._ngEl.nativeElement.classList.add(klass);
    }
  }

  removeCustomClass(klass: string) {
    if (this._ngEl.nativeElement.classList.contains(klass) === true) {
      this._ngEl.nativeElement.classList.remove(klass);
    }
  }

  chkValid(name: string | number): boolean {
    if (name === null) {
      return false;
    }
    let c = this.getControl(name.toString());
    if (!c) {
      return false;
    }
    if (this.chkSubmitted()) {
      return c.valid;
    } else {
      return (c.touched || c.dirty) && c.valid;
    }
  }

  chkInvalid(name: string | number): boolean {
    if (name === null) {
      return false;
    }
    let c = this.getControl(name.toString());
    if (!c) {
      return false;
    }

    // this._ngForm && this._ngForm.submitted
    if (this.chkSubmitted()) {
      return c.invalid;
    } else {
      return (c.touched || c.dirty) && c.invalid;
    }
  }

  chkSubmitted() {
    let form: any = this._parent;
    if (this._parent instanceof FormGroupName) {
      // FormGroupName
      return form._parent.submitted;
    } else if (this._parent instanceof FormArrayName) {
      return form._parent.submitted;
    } else {
      // NgForm, FormGroupDirective
      return form.submitted;
    }
  }

  @Input('npFcvName') name: string | string[] | number | number[] | null = null;
  @Input('npValidClass') npValidClass: string | null = null;
  @Input('npInvalidClass') npInvalidClass: string | null = null;

  getPath(name: string | number | null): string[] {
    return this.controlPath(name === null ? null : name.toString(), this._parent!);
  }

  controlPath(name: string | null, parent: ControlContainer): string[] {
    return [...parent.path!, name!];
  }

  get formDirective(): any {
    return this._parent ? this._parent.formDirective : null;
  }

  // _names: string[] | number[];
  _names: Array<string | number> | undefined;

  _parent: ControlContainer | null = null;
  // _ngForm: NgForm | null = null;

  private supportParentClassType: string[] = ['NgForm', 'FormGroupDirective', 'FormGroupName'];
  constructor(
    private _ngEl: ElementRef,
    private _renderer: Renderer2,
    @Optional() @Host() @SkipSelf() parent: ControlContainer) {

    // if (!parent) {
    //   console.log('need check type of parent');
    // }

    // if (!this.supportParentClassType.includes(parent.constructor.name)) {
    //   console.log('need check type of parent - ' + parent.constructor.name);
    // }

    this._parent = parent;
  }

  ngOnInit() {

    if (!this._parent) {
      NpFcvNameDirective.controlParentException();
    }

    if (!this.name === null) {
      NpFcvNameDirective.missingnpFcvNameException();
    }

    if (Array.isArray(this.name)) {
      this._names = this.name as any[];
    } else {
      this._names = [this.name as any];
    }

    if (this._names === undefined) {
      NpFcvNameDirective.missingnpFcvNameException();
    }

  }

  getControl(name: string | number): AbstractControl | null {
    if (!this._parent) {
      return null;
    } if (!this._parent.control) {
      return null;
    }
    return this._parent.control.get(name.toString());
  }

  static controlParentException(): void {
    throw new Error(
      `npFcvName must be used with a parent formGroup directive.  You'll want to add a formGroup
       directive and pass it an existing FormGroup instance (you can create one in your class).`);
  }

  static missingnpFcvNameException() {
    throw new Error(
      `npFcvName needs name of ngModel or formControlName.

      Example 1: <Element npFcvName="firstname">
      Example 2: <Element [npFcvName]="['firstname', 'lastname']]`);
  }

}
